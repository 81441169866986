import {observer} from 'mobx-react-lite';
import styles from '@/components/form/Editable.module.css';
import {openModal} from '@/components/modal/ModalContext';
import {noWhitespaceUnlessLiteralSpace} from '@/model/common/validator';
import {requestAnimationFrameAsync} from '@/utils/animation';
import {constructErrorMeta} from '@/utils/errors';
import {stopPropagationAndPreventDefault} from '@/utils/stopPropagationAndPreventDefault';
import {useValtioProxy, valtioRef, when} from '@/utils/valtio';
import {useBlocker} from 'react-router';
import clsx from 'clsx';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  type ForwardedRef,
} from 'react';
import type {ZodTypeAny} from 'zod';

interface EditableProps {
  name: string;
  className?: string;
  validator: ZodTypeAny;
  onEdit: ((value: string, onEditProps: any) => void) | undefined;
  onEditProps?: any;
  children: string;
  spellCheck?: boolean;
  truncate?: boolean;
}

export type EditableRef = {
  click: () => Promise<void>;
  selectAll: () => Promise<void>;
};

export default observer(
  forwardRef(function Editable(props: EditableProps, ref: ForwardedRef<EditableRef>) {
    const {
      name,
      className,
      onEdit,
      onEditProps,
      children,
      spellCheck = false,
      truncate,
      validator,
    } = props;
    const [editing, setEditing] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const contenteditableRef = useRef<HTMLDivElement>(null);
    const [contenteditableRefCurrent, contenteditableRefCurrentProxy] = useValtioProxy(
      contenteditableRef.current,
    );
    useEffect(() => {
      if (contenteditableRef.current !== contenteditableRefCurrentProxy.value) {
        // this is a hack to fix a race condition where the ref isn't always defined.
        // this causes a double-render on initial mount, which is bad, but not sure how else to fix it
        contenteditableRefCurrentProxy.value = contenteditableRef.current
          ? valtioRef(contenteditableRef.current)
          : contenteditableRef.current;
      }
    }, [contenteditableRef, contenteditableRefCurrentProxy]);

    useImperativeHandle<EditableRef, EditableRef>(ref, () => {
      return {
        async click() {
          await requestAnimationFrameAsync();
          await when((get) => {
            return !!get(contenteditableRefCurrentProxy).value;
          });
          contenteditableRefCurrentProxy.value!.click();
          await requestAnimationFrameAsync();
          contenteditableRefCurrentProxy.value!.focus();
        },
        async selectAll() {
          await requestAnimationFrameAsync();
          const range = document.createRange();
          const selection = window.getSelection();

          if (selection) {
            selection.removeAllRanges();
            await when(() => {
              return !!contenteditableRefCurrentProxy.value;
            });
            range.selectNodeContents(contenteditableRefCurrentProxy.value!);
            selection.addRange(range);
          }
        },
      };
    });

    const shouldBlock = useCallback(
      function ({currentLocation, nextLocation}) {
        return !!(
          contenteditableRef.current &&
          editing &&
          currentLocation.pathname !== nextLocation.pathname
        );
      },
      [editing, contenteditableRef],
    );
    const blocker = useBlocker(shouldBlock);

    // TODO move this more centrally maybe
    useEffect(() => {
      if (blocker.state === 'blocked') {
        openModal({
          title: 'Unsaved changes',
          content: 'You have unsaved changes. Would you like to discard them and proceed?',
          styleType: 'destructive',
          formSpec: {
            submitText: 'Proceed',
            async onSubmit() {
              await when((get) => {
                return !!get(contenteditableRefCurrentProxy).value;
              });
              contenteditableRefCurrentProxy.value!.innerHTML = children;
              if (document.activeElement !== contenteditableRefCurrent.value) {
                // blur event won't fire if this has becomed unfocused on mobile
                contenteditableRefCurrent.value!.focus();
              }
              contenteditableRefCurrent.value!.blur();
              blocker.proceed();
            },
          },
          onCancelClick() {
            blocker.reset();
          },
        });
      }
    }, [
      blocker,
      children,
      blocker.state,
      contenteditableRefCurrentProxy,
      contenteditableRefCurrent,
    ]);

    const onReadOnlyClick = useCallback(
      async function () {
        if (onEdit) {
          setEditing(true);
        }
      },
      [onEdit],
    );

    const onBlur = useCallback(
      function (event) {
        const value = event.currentTarget.textContent;
        event.currentTarget.scrollLeft = 0; // if this is scrolled to the right, blur will cause truncation in the middle of the scroll, leaving the heading in a weird spot
        if (value !== children) {
          try {
            const validated = validator.parse(value);
            const result = onEdit!(validated, onEditProps);
            setEditing(false);
            setErrors([]);
            return result;
          } catch (e) {
            const error = e as Error;
            const errorsMeta = constructErrorMeta(error);
            if (errorsMeta.form?.length) {
              setErrors(errorsMeta.form);
            }
            contenteditableRef.current?.focus();
          }
        } else {
          setErrors([]);
          setEditing(false);
        }
      },
      [children, onEdit, onEditProps, setErrors, contenteditableRef, validator],
    );

    const onKeyDown = useCallback(
      function (event) {
        if (event.key === 'Enter') {
          contenteditableRef.current?.blur();
          stopPropagationAndPreventDefault(event);
          return;
        }
        if (event.key === 'Escape') {
          event.currentTarget.innerHTML = children;
          contenteditableRef.current?.blur();
          return;
        }
      },
      [contenteditableRef, children],
    );

    const onBeforeInput = useCallback(function (event) {
      const char = event.data;
      const {success} = noWhitespaceUnlessLiteralSpace.safeParse(char);
      if (!success) {
        stopPropagationAndPreventDefault(event);
      }
    }, []);

    const conditionalClassnames = editing
      ? clsx(
          styles.Editable_textbox_editing,
          'underline underline-offset-4',
          truncate && 'overflow-y-hidden',
          truncate && 'overflow-x-hidden',
          !!errors.length && 'decoration-red-700',
        )
      : clsx(
          onEdit && styles.Editable_textbox_editable,
          onEdit && 'hover:underline hover:decoration-gray-300',
          truncate && 'truncate',
        );

    return (
      <div>
        <div>
          <div
            role={onEdit ? 'textbox' : undefined}
            ref={contenteditableRef}
            aria-label={name}
            className={clsx(
              'Editable shrink underline-offset-4',
              className,
              styles.Editable_textbox,
              conditionalClassnames,
            )}
            {...(onEdit ? {contentEditable: 'plaintext-only'} : {})}
            onBlur={editing ? onBlur : undefined}
            onKeyDown={editing ? onKeyDown : undefined}
            onBeforeInput={editing ? onBeforeInput : undefined}
            spellCheck={onEdit ? spellCheck : undefined}
            autoCapitalize={onEdit ? 'off' : undefined}
            onClick={editing ? undefined : onReadOnlyClick}
            suppressContentEditableWarning
          >
            {children}
          </div>
        </div>
        {!!errors.length && (
          <div className="text-red-700 animate-fade-in text-base font-serif font-normal leading-normal tracking-normal">
            {errors.map((error, i) => {
              return (
                <div
                  id={`${name}-error-${i}`}
                  className="pt-1"
                  key={`${error}-${i}`}
                >
                  {error}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }),
);
