import {useEffect, useMemo} from 'react';
import WebSocketEventEmitter from './WebSocketEventEmitter';
import type {SocketUpdateEvent, SocketUpdateEventWithOrgId} from '@/events/SocketUpdateEvents';
import {unstable_batchedUpdates} from 'react-dom';
import type {OrgId} from '@/model/org/types';
import {runInAction} from 'mobx';

export default function useWebSocketEvent<T extends SocketUpdateEvent['type']>(
  eventName: T,
  handler: (
    data: Extract<SocketUpdateEvent, {type: T}>['data'],
    clientContext?: {optimisticId: string},
  ) => void,
  shouldIgnore?: (event: SocketUpdateEvent) => boolean,
) {
  useEffect(() => {
    function listener(event: Event) {
      const {detail} = event as CustomEvent;
      if (shouldIgnore?.(detail as SocketUpdateEvent)) {
        return;
      }
      if (
        Array.isArray(detail.data) &&
        // assume that if there are multiple items coming in the socket event that this was a batch operation and we should batch client updates
        detail.data.length > 1
      ) {
        unstable_batchedUpdates(() => {
          runInAction(() => {
            handler(detail.data, detail.clientContext);
          });
        });
      } else {
        runInAction(() => {
          handler(detail.data, detail.clientContext);
        });
      }
    }
    WebSocketEventEmitter.on(eventName, listener);
    return () => {
      WebSocketEventEmitter.off(eventName, listener);
    };
    // we only want this to run on mount and unmount since we're going to be passing a new function in every time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function useOrgWebSocketEvent<T extends SocketUpdateEventWithOrgId['type']>(
  orgId: OrgId,
  eventName: T,
  handler: (
    data: Extract<SocketUpdateEventWithOrgId, {type: T; orgId: OrgId}>['data'],
    clientContext?: {optimisticId: string},
  ) => void,
) {
  return useWebSocketEvent(
    eventName,
    // @ts-expect-error not sure how to fix this
    handler,
    (event) => {
      // @ts-expect-error not sure how to fix this
      return event.orgId !== orgId;
    },
  );
}
