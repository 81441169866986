import type {UIMatch} from 'react-router';
import {useMatches, useParams} from 'react-router';
import {observer} from 'mobx-react-lite';

import Editable, {type EditableRef} from '@/components/form/Editable';
import type {RouteHandle} from '@/router-types';
import {requestAnimationFrameAsync} from '@/utils/animation';

import clsx from 'clsx';
import {useEffect, useRef, type MutableRefObject} from 'react';
import {useValtioSnapshot} from '@/utils/valtio';
import {currentUserProxy} from '@/context/UserContext';

type MainHeadingProps = {
  className?: string;
};

const mainHeadingRef: MutableRefObject<EditableRef | null> = {
  current: null,
};

export async function editMainHeading() {
  await requestAnimationFrameAsync();
  return mainHeadingRef.current?.click();
}

export const MainHeading = observer(function MainHeading(props: MainHeadingProps = {}) {
  const ref = useRef<EditableRef>(null);
  useEffect(() => {
    mainHeadingRef.current = ref.current;
  }, [ref]);
  const {className} = props;
  const params = useParams();
  const matches = useMatches() as UIMatch<unknown, RouteHandle | undefined>[];
  const currentUser = useValtioSnapshot(currentUserProxy);

  const handle = matches[matches.length - 1].handle;
  if (!handle || handle.layout === 'full-screen-centered') {
    return null;
  }
  const {getHeading, editHeadingMeta} = handle;
  const heading = getHeading({params});
  const onEdit = editHeadingMeta?.getCanEdit({params}, {currentUser})
    ? editHeadingMeta.execute
    : undefined;
  const onEditProps = onEdit ? {params} : null;
  const validator = onEdit ? editHeadingMeta?.validator : undefined;
  return (
    <header className={clsx('MainHeading', className, 'truncate')}>
      <h1>
        <Editable
          ref={ref}
          name="heading"
          onEdit={onEdit}
          onEditProps={onEditProps}
          // @ts-expect-error not sure how to fix this easily
          validator={validator}
          truncate
        >
          {heading}
        </Editable>
      </h1>
    </header>
  );
});
